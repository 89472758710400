.loading-content {
  margin: auto;
  display: grid;
  place-items: center;
}

#truck-rect-1 {
  animation: rect-1 0.85s infinite;
}

#truck-rect-2 {
  animation-delay: 0.6s;
  animation: rect-2 0.85s infinite;
}

#truck-rect-3 {
  animation-delay: 0.3s;
  animation: rect-3 0.85s infinite;
}

#truck-street-mark {
  animation: truck-street-mark 0.85s 0.5s infinite;
}

#truck {
  animation: truck-moviment 0.85s infinite, truck-opacity 0.85s infinite;
}

@keyframes truck-opacity {
  0%,
  100% {
    fill: var(--gray-gray60);
  }
  40% {
    fill: var(--gray-gray40);
  }
}

@keyframes truck-moviment {
  0%,
  40%,
  70%,
  100% {
    transform: translateY(0px);
  }
  60% {
    transform: translateY(-1.5px);
  }
}

@keyframes truck-street-mark {
  from {
    width: 20;
    x: 60;
  }
  to {
    x: -20;
    width: 20;
  }
}

@keyframes rect-1 {
  from {
    width: 0;
    opacity: 1;
    x: 13.39773;
  }
  to {
    opacity: 0;
    x: 6.125;
    width: 7.27273;
  }
}

@keyframes rect-2 {
  from {
    width: 0;
    opacity: 1;
    x: 13.39421;
  }
  to {
    opacity: 0;
    x: 1.75781;
    width: 7.27273;
  }
}
@keyframes rect-3 {
  from {
    width: 0;
    opacity: 1;
    x: 13.39205;
  }
  to {
    opacity: 0;
    x: 3.9375;
    width: 9.45455;
  }
}
