/* Novo slider */
@keyframes dots {
  0% {
    width: 10px;
  }
  100% {
    width: 20px;
  }
}
@keyframes dots-reverse {
  0% {
    width: 20px;
  }
  100% {
    width: 10px;
  }
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border: none;
  margin: 0px 4px;
  transition: all 1;
  border-radius: 10px;
  animation: dots-reverse 350ms;
  background-color: var(--gray-gray40);
}

.swiper-pagination-bullet-active {
  width: 20px;
  animation: dots 350ms;
  background-color: var(--brand-red);
}

.swiper-pagination {
  position: initial !important;
}

.fastWay {
  height: 58px !important;
}

.promotional .swiper-slide {
  width: 85%;
  height: auto;
  margin-bottom: 10px;
}
