:root {
  --link: #61dafb;
  --grey2: #777777;
  --grey3: #828282;
  --grey4: #eeeeee;
  --grey9: #676767;
  --grey10: #f2f2f2;
  --green: #27ae61;
  --white: #ffffff;
  --brand-red: #ff3219;
  --shadown1: #0000001a;
  --shadown2: #00000026;
  --shadown5: #00000030;
  --app-header: #282c34;
  --background2: #f5f5f5;
  --gray-gray40: #999999;
  --gray-gray50: #666666;
  --gray-gray60: #4f4f4f;
  --gray-gray10: #f0f0ee;
  --mobile: 500px;
}

* {
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  margin: 0px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
  max-width: var(--mobile);
  background-color: var(--grey4);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body:has(.modal-content-open) {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

@keyframes enter-efect {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.enter-efect {
  opacity: 1;
  animation: enter-efect 1000ms;
}

.disabled-service {
  filter: blur(0.4px) brightness(0.75);
}

.disabled {
  opacity: .3;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: #bbb;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #333;
  border: 3px solid #bbb;
}

#root:has(.full-wrapper) {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
