h2 {
  margin-top: 40px;
}

.shadown {
  top: 105px;
  z-index: 9;
  margin-bottom: -10px;
  position: sticky;
  margin-left: -10px;
  margin-right: -10px;
  box-shadow: 0px 0px 3px 4px var(--shadown2);
}

.loading {
  width: 40px;
}

.loading-text {
  color: var(--grey2);
}

.filters {
  top: 50px;
  z-index: 9;
  position: sticky;
  background: var(--background2);
}

.products {
  width: 100%;
  height: 100vh;
  padding-bottom: 50px;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center;
  background: var(--background2);
}

.bg-black {
  gap: 1rem;
  width: 100%;
  height: 100%;
  margin-top: 15px;
  border-radius: 10px;
  padding: 0px 1rem 1rem;
  box-shadow: var(--shadown1) 0px 0px 8px;
  display: flex;
  position: relative;
  background: var(--white);
  align-items: center;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.product-image {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.rounded {
  border-radius: 10px;
}

.plus {
  margin-bottom: 3px;
}

.btn-add {
  width: 100%;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  line-height: normal;
  cursor: pointer;
  transition: all 0.15s ease 0s;
  padding: 10px 25px;
  background: var(--brand-red);
  color: var(--white);
  border: none;
}

.btn-add-list {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  background-color: var(--brand-red);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  position: absolute;
  z-index: 1;
  top: 4px;
  right: 4px;
  cursor: pointer;
}

.btn-add-list:hover {
  box-shadow: var(--shadown5) 0px 4px 20px 0px;
}

.ean {
  overflow: hidden;
  color: var(--gray-gray40);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 5px;
  line-height: 140%;
}

.select-embalagem {
  text-align: center;
  font-size: 11px;
  margin-bottom: 0px;
  flex: 1;
  white-space: pre;
  font-weight: 400;
  color: var(--gray-gray60);
}

.box-image {
  display: flex;
  position: relative;
  align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 18%;
  padding-bottom: 0px;
}

.desc-product {
  font-size: 14px;
  font-weight: 700;
  font-family: Roboto, sans-serif;
  line-height: 16px;
  min-height: 40px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: left;
  padding: 0px;
  margin-bottom: unset;
  color: var(--gray-gray60);
}

#condition-select {
  max-width: 200px;
}

.box-qtd-embalagem {
  width: 100%;
  padding: 8px 10px;
  border-radius: 0px 6px 6px;
  background-color: var(--gray-gray10);
}

.qtd-embalagem {
  white-space: nowrap;
  font-weight: 400;
  font-size: 11px;
  text-align: left;
  line-height: 120%;
  margin-bottom: 0px;
  color: var(--gray-gray60);
}

.box-embalagem {
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 5px;
  -webkit-box-align: center;
  align-items: center;
  width: 50px;
  height: 50px;
  padding-top: 7px;
  border-radius: 6px 6px 0px 0px;
  cursor: pointer;
  border-top: 2px solid var(--gray-gray50);
  background-color: var(--gray-gray10);
}

.check {
  position: absolute;
  color: var(--green);
  right: -5px;
  top: -10px;
  background: var(--white);
  border-radius: 100%;
  padding: 1px;
}

.box-label {
  color: var(--gray-gray50);
  text-align: center;
  font-size: 9px;
  font-weight: 500;
  margin: 0px;
  line-height: normal;
}

.price {
  margin-bottom: 0px;
  display: flex;
  font-weight: 700;
  line-height: normal;
  font-size: 16px;
  text-align: left;
  color: var(--gray-gray60);
}

.unit-price {
  margin-bottom: 0px;
  color: var(--grey3);
  font-size: 12px;
  line-height: normal;
  text-align: left;
  font-weight: 400;
  line-height: 140%;
}

.ri-arrow-down-s-line {
  color: var(--grey9);
  font-size: 12px !important;
}

.underline {
  margin-bottom: 0px;
  display: flex;
  line-height: normal;
  font-weight: 400;
  font-size: 12px;
  text-decoration-line: line-through;
  color: var(--gray-gray40);
}

.line {
  width: auto;
  flex: auto;
  height: 1px;
  background-color: var(--grey10);
}
.label-select-embalagem {
  line-height: 10px;
  margin-bottom: 5px;
  align-items: center;
  display: flex;
  gap: 15px;
  grid-column: auto;
}

.content-chevron {
  line-height: 1px;
  margin-bottom: 2px;
}

.add-list {
  position: absolute;
  right: 5px;
  top: 5px;
}

.form-label {
  white-space: nowrap;
}
